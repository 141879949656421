<!-- 
	This is the delivery page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<a-row :gutter="24" type="flex">
			<a-col  :span="24" class="mb-24">
				<CardDeliveriesActions
					:data="deliveriesData"
					:columns="table1Columns"
					:permissionsInfo="permissionsInfo">
				</CardDeliveriesActions>

			</a-col>

		</a-row>
		<!-- Deliveries Table -->
		<a-row :gutter="24" type="flex">

			<!-- Deliveries Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Deliveries Table Card -->
				<CardDeliveriesTable
					:data="deliveriesData"
					:columns="table1Columns"
					:permissionsInfo="permissionsInfo">
				></CardDeliveriesTable>
				<!-- / Deliveries Table Card -->

			</a-col>
			<!-- / Deliveries Table Column -->

		</a-row>
		<!-- / Deliveries Table -->

	</div>
</template>

<script>

	// "Deliveries" table component.
	import CardDeliveriesTable from '../components/Cards/CardDeliveriesTable.vue';
	import CardDeliveriesActions from '../components/Cards/CardDeliveriesActions.vue';
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
	
	// "Deliveries" table list of columns and their properties.
	
	
	
	const table1Columns = [
		{
			title: 'Date Added',
			dataIndex: 'date',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => new Date(a.date) - new Date(b.date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Resident',
			dataIndex: 'owner',
			scopedSlots: { customRender: 'owner' },
			sorter: (a, b) => a.owner.apartment.localeCompare(b.owner.apartment), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Type',
			dataIndex: 'type',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => a.type.localeCompare(b.type), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Vendor',
			dataIndex: 'from',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => a.from.localeCompare(b.from), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Status',
			scopedSlots: { customRender: 'status' },
			sorter: (a, b) => a.status.localeCompare(b.status), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Pickup Time',
			dataIndex: 'pickup_time',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => new Date(a.pickup_time) - new Date(b.pickup_time), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Received By',
			dataIndex: 'received_by',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => a.received_by.localeCompare(b.received_by), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Packages',
			dataIndex: 'number_packages',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => a.number_packages.localeCompare(b.number_packages), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		
		
		{
			title: '',
			scopedSlots: { customRender: 'actionsBtn' },
		},
	];

	

	export default ({
		components: {
    CardDeliveriesTable,
	CardDeliveriesActions
},
		data() {
			return {

				// Associating "Deliveries" table columns with its corresponding property.
				table1Columns: table1Columns,

				
			}
		},
		computed: {
			...mapState({
				deliveriesData: state => state.deliveries.deliveries,
				permissionsInfo: state => state.deliveries.permissionsInfo, 
			})
		},
		methods: {
			...mapActions({
				getDeliveries: 'deliveries/getDeliveries',
				initAllowedActions: 'deliveries/initAllowedActions',
				
			}),
		},
		async mounted() {
			window.analytics.page('Deliveries');
			await this.initAllowedActions();
			await this.getDeliveries();
		},
	})

</script>

<style lang="scss">
</style>